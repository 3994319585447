import MenuBookIcon from '@mui/icons-material/MenuBook'
import ArticleIcon from '@mui/icons-material/Article'
import PolicyIcon from '@mui/icons-material/Policy'
import SchoolIcon from '@mui/icons-material/School'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PodcastsIcon from '@mui/icons-material/Podcasts'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import StarIcon from '@mui/icons-material/Star'
import PostAddIcon from '@mui/icons-material/PostAdd'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import InfoIcon from '@mui/icons-material/Info'
import HomeIcon from '@mui/icons-material/Home'

// routes for the whole site (all routes in PAGES the other consts just make things more convienent)

export const PAGES = {
    home: {
        desktop_title: 'T-CAIREM RESOURCES HUB',
        mobile_title: 'RESOURCES HUB',
        dropdown_mobile_title: 'Home',
        path: '/'
    },
    about: {
        title: 'About',
        path: '/about/'
    },
    start: {
        title: 'Start Here',
        path: '/start-here/'
    },
    resources: {
        title: 'Resources',
        path: '/resources/'
    },
    login: {
        title: 'Login',
        path: '/login/'
    },
    profile: {
        title: 'Profile',
        path: '/profile/'
    },
    books: {
        title: 'Books',
        path: '/books/'
    },
    policies: {
        title: 'Policy',
        path: '/policies/'
    },
    courses: {
        title: 'Courses',
        path: '/courses/'
    },
    papers: {
        title: 'Papers',
        path: '/papers/'
    },
    podcasts: {
        title: 'Podcasts',
        path: '/podcasts/'
    },
    videos: {
        title: 'Videos',
        path: '/videos/'
    },
    favourites: {
        title: 'Favourites',
        path: '/favourites/'
    },
    ratings: {
        title: 'Ratings',
        path: '/ratings/'
    },
    request: {
        title: 'Request Resource',
        path: '/request-resource/'
    },
    forgot_password: {
        title: 'Forgot Password',
        path: '/forgot-password/'
    },
    confirm_forgot_password: {
        title: 'Set New Password',
        path: '/confirm-forgot-password/'
    },
    sign_up: {
        title: 'Sign Up',
        path: '/sign-up/'
    },
    activate: {
        title: 'Activate Account',
        path: '/activate/'
    },
    admin_portal: {
        title: 'Admin Portal',
        path: '/admin-portal/'
    },
    manage_resources: {
        title: 'Manage Current Resources',
        path: '/manage-resources/'
    },
    manage_requests: {
        title: 'Manage Pending Requests',
        path: '/manage-requests/'
    },
    manage_admin: {
        title: 'Manage Admin',
        path: '/manage-admin/'
    },
    manage_tags: {
        title: 'Manage Resource Tags',
        path: '/manage-tags/'
    },
}

export const RESOURCES = [
    {
        title: PAGES.books.title,
        path: PAGES.books.path,
        icon: MenuBookIcon
    },
    {
        title: PAGES.courses.title,
        path: PAGES.courses.path,
        icon: SchoolIcon
    },
    {
        title: PAGES.papers.title,
        path: PAGES.papers.path,
        icon: ArticleIcon
    },
    {
        title: PAGES.policies.title,
        path: PAGES.policies.path,
        icon: PolicyIcon
    },
    {
        title: PAGES.podcasts.title,
        path: PAGES.podcasts.path,
        icon: PodcastsIcon
    },
    {
        title: PAGES.videos.title,
        path: PAGES.videos.path,
        icon: PlayArrowIcon
    },
]

export const SETTINGS = [
    {
        title: PAGES.profile.title,
        path: PAGES.profile.path,
        icon: ManageAccountsIcon
    },
    {
        title: PAGES.favourites.title,
        path: PAGES.favourites.path,
        icon: BookmarkIcon
    },
    {
        title: PAGES.ratings.title,
        path: PAGES.ratings.path,
        icon: StarIcon
    },
    {
        title: PAGES.request.title,
        path: PAGES.request.path,
        icon: PostAddIcon
    },
]

export const MOBILE_NAV_ROUTES = [
    {
        mobile_title: PAGES.home.mobile_title,
        title: PAGES.home.dropdown_mobile_title,
        path: PAGES.home.path,
        icon: HomeIcon,
        user: false,
        staff: false
    },
    {
        title: PAGES.about.title,
        path: PAGES.about.path,
        icon: InfoIcon,
        user: false,
        staff: false
    },
    {
        title: PAGES.resources.title,
        path: PAGES.resources.path,
        icon: ArticleIcon,
        user: false,
        staff: false
    },
    {
        title: PAGES.profile.title,
        path: PAGES.profile.path,
        icon: ManageAccountsIcon,
        user: true,
        staff: false
    },
    {
        title: PAGES.favourites.title,
        path: PAGES.favourites.path,
        icon: BookmarkIcon,
        user: true,
        staff: false
    },
    {
        title: PAGES.ratings.title,
        path: PAGES.ratings.path,
        icon: StarIcon,
        user: true,
        staff: false
    },
    {
        title: PAGES.request.title,
        path: PAGES.request.path,
        icon: PostAddIcon,
        user: true,
        staff: false
    },
    {
        title: PAGES.admin_portal.title,
        path: PAGES.admin_portal.path,
        icon: AdminPanelSettingsIcon,
        user: true,
        staff: true
    }
]
