import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Select from 'react-select'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

import { RequestFormStyle } from '../styles'
import { setErrorNotification, setSuccessNotification } from '../../reducers/notificationReducer'
import { createPaper } from '../../reducers/papersReducer'

import resourceService from '../../services/resources'

const PaperForm = ({tags, user}) => {
    const dispatch = useDispatch()

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)
    const [nameHelper, setNameHelper] = useState('')
    const [author, setAuthor] = useState('')
    const [authorError, setAuthorError] = useState(false)
    const [authorHelper, setAuthorHelper] = useState("As provided in paper's citation")
    const [link, setLink] = useState('')
    const [linkError, setLinkError] = useState(false)
    const [linkHelper, setLinkHelper] = useState('')
    const [date, setDate] = useState('')
    const [dateError, setDateError] = useState(false)
    const [dateHelper, setDateHelper] = useState('')
    const [doi, setDoi] = useState('')
    const [doiError, setDoiError] = useState(false)
    const [doiHelper, setDoiHelper] = useState('')
    const [formTags, setFormTags] = useState('')
    const [volume, setVolume] = useState('')
    const [journal, setJournal] = useState('')
    const [pages, setPages] = useState('')
    const [issue, setIssue] = useState('')
    const [hasDataset, setHasDataset] = useState(false)
    const [datasetLink, setDatasetLink] = useState('')
    const [hasModel, setHasModel] = useState(false)
    const [modelLink, setModelLink] = useState('')
    const [searchDoi, setSearchDoi] = useState('')
    const [searchDoiError, setSearchDoiError] = useState(false)
    const [searchDoiHelper, setSearchDoiHelper] = useState('')
    const [paperType, setPaperType] = useState('RS')

    const validate = (val, errorHandler, helperHandler) => {
        if (val.length === 0) {
            errorHandler(true)
            helperHandler('Required Field!')
            return false
        } else {
            errorHandler(false)
            helperHandler('')
            return true
        }
    }

    const reset = () => {
        setName('')
        setNameError(false)
        setNameHelper('')
        setAuthor('')
        setAuthorError(false)
        setAuthorHelper('')
        setLink('')
        setLinkError(false)
        setLinkHelper('')
        setDate('')
        setDateError(false)
        setDateHelper('')
        setDoi('')
        setDoiError(false)
        setDoiHelper('')
        setFormTags('')
        setDoi('')
        setFormTags('')
        setVolume('')
        setJournal('')
        setPages('')
        setIssue('')
        setHasDataset(false)
        setDatasetLink('')
        setHasModel(false)
        setModelLink('')
        setPaperType('RS')
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (validate(name, setNameError, setNameHelper) && validate(author, setAuthorError, setAuthorHelper) && validate(link, setLinkError, setLinkHelper) && validate(date, setDateError, setDateHelper) && validate(doi, setDoiError, setDoiHelper)) {
            const data = {
                title: name,
                author: author,
                link: link,
                pub_date: date,
                doi: doi,
                paper_type: paperType,
                has_model: hasModel,
                has_dataset: hasDataset,
                model_link: modelLink,
                dataset_link: datasetLink
            };

            if(formTags && formTags.length > 0) data.tags = formTags.map(tag => tag.value)
            else data.tags = []

            if(volume) data.volume = volume
            if(journal) data.journal = journal
            if(pages) data.pages = pages
            if(issue) data.issue = issue

            dispatch(createPaper(data, user.staff))
            reset()
        } else {
            dispatch(setErrorNotification('Please fill all required fields before submission'))
        }
    }

    const handleSearch = async () => {
        if (searchDoi === '') {
            setSearchDoiError(true)
            setSearchDoiHelper('Please fill this field first!')
            return
        }
        setSearchDoiError(false)
        setSearchDoiHelper('')

        try {
            const res = await resourceService.retrievePaper(searchDoi)
            setName(res.message.title[0])
            setAuthor(res.message.author.reduce((prev, cur) => {
                if (prev === '') {
                    return `${cur.given} ${cur.family}`
                } else {
                    return prev + `, ${cur.given} ${cur.family}`
                }
            }, ''))
            if(res.message.created["date-time"]) setDate(res.message.created["date-time"].split('T')[0])
            setLink(res.message.resource.primary.URL)
            setDoi(res.message.DOI)
            if(res.message.volume) setVolume(res.message.volume)
            if(res.message['container-title']) setJournal(res.message['container-title'][0])
            if(res.message.page) setPages(res.message.page)
            if(res.message.issue) setIssue(res.message.issue)
            dispatch(setSuccessNotification('Paper Found'))
        } catch {
            dispatch(setErrorNotification('A paper could not be found with that DOI'))
        }

        setSearchDoi('')
    }

    return (
        <>
            <Box sx={RequestFormStyle.searchContainer}>
                <Box sx={RequestFormStyle.searchTextContainer}>
                    <Typography {...RequestFormStyle.searchText} gutterBottom>
                        Need help filling fields? You can fill fields automatically by searching with the paper DOI below.
                        Note you will still need to fill the key words.
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            value={searchDoi}
                            onChange={e => setSearchDoi(e.target.value)}
                            label="DOI"
                            helperText={searchDoiHelper}
                            error={searchDoiError}
                        />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <Button {...RequestFormStyle.searchButton.props} sx={RequestFormStyle.searchButton.style} fullWidth onClick={()=>handleSearch()}>
                            Search DOI
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <br />
            <Container {...RequestFormStyle.container}>
                <Typography {...RequestFormStyle.title.props} gutterBottom sx={RequestFormStyle.title.style}>
                    Paper Form
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabel id="paper-type">Paper Type</FormLabel>
                                    <RadioGroup row aria-labelledby="paper-type" name="paper-types" defaultValue={"RS"}>
                                        <FormControlLabel value="RS" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Research" />
                                        <FormControlLabel value="SPD" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Software Package Descriptor" />
                                        <FormControlLabel value="RV" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Review" />
                                        <FormControlLabel value="DD" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Dataset Description" />
                                        <FormControlLabel value="COM" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Perspective/Commentary" />
                                        <FormControlLabel value="ED" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Editorial" />
                                        <FormControlLabel value="SYS" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Systematic Review" />
                                        <FormControlLabel value="TUT" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Tutorial" />
                                        <FormControlLabel value="REP" control={<Radio onClick={(e) => setPaperType(e.target.value)} />} label="Report" />
                                    </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={name}
                                onChange={e => setName(e.target.value)}
                                label="Name"
                                required
                                error={nameError}
                                helperText={nameHelper}
                                onBlur={()=>validate(name, setNameError, setNameHelper)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={author}
                                onChange={e => setAuthor(e.target.value)}
                                label="Author(s)"
                                required
                                error={authorError}
                                helperText={authorHelper}
                                onBlur={()=>validate(author, setAuthorError, setAuthorHelper)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={link}
                                type='url'
                                onChange={e => setLink(e.target.value)}
                                label="Link to Resource"
                                required
                                error={linkError}
                                helperText={linkHelper}
                                onBlur={()=>validate(link, setLinkError, setLinkHelper)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={date}
                                type='date'
                                onChange={e => setDate(e.target.value)}
                                label="Publish Date"
                                required
                                InputLabelProps={{ shrink: true }}
                                error={dateError}
                                helperText={dateHelper}
                                onBlur={()=>validate(date, setDateError, setDateHelper)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                value={doi}
                                onChange={e => setDoi(e.target.value)}
                                label="DOI"
                                required
                                error={doiError}
                                helperText={doiHelper}
                                onBlur={()=>validate(doi, setDoiError, setDoiHelper)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={volume}
                                onChange={e => setVolume(e.target.value)}
                                label="Volume"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={issue}
                                onChange={e => setIssue(e.target.value)}
                                label="Issue"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={journal}
                                onChange={e => setJournal(e.target.value)}
                                label="Journal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                value={pages}
                                onChange={e => setPages(e.target.value)}
                                label="Pages"
                                placeholder='Suggested Page Range'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                closeMenuOnSelect={false}
                                isMulti
                                options={tags}
                                value={formTags}
                                onChange={(value) => setFormTags(value)}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        minHeight: '56px'
                                    })
                                }}
                                isOptionDisabled={() => formTags.length >= 5}
                                placeholder='Select Key Words'
                            />
                        </Grid>

                        <br />

                        <Grid item xs={12} sm={6}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Does the paper have an attached dataset?</FormLabel>
                                <RadioGroup row aria-label="hasDataset" name="hasDataset" value={hasDataset} onChange={(e) => setHasDataset(e.target.value === 'true')}>
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Does the paper have an attached model?</FormLabel>
                                <RadioGroup row aria-label="hasModel" name="hasModel" value={hasModel} onChange={(e) => setHasModel(e.target.value === 'true')}>
                                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        {hasDataset && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={datasetLink}
                                    onChange={e => setDatasetLink(e.target.value)}
                                    label="Dataset URL"
                                    placeholder='Link to Dataset'
                                    required
                                />
                            </Grid>
                        )}
                        {hasModel && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={modelLink}
                                    onChange={e => setModelLink(e.target.value)}
                                    label="Model URL"
                                    placeholder='Link to Model'
                                    required
                                />
                            </Grid>
                        )}

                        <Grid item xs={4}>
                            <Button sx={RequestFormStyle.button.style} {...RequestFormStyle.button.props} type='submit'>{user.staff? 'Create Paper' : 'Request Paper'}</Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </>
    )
}

export default PaperForm